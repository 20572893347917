import React from "react";
import DividerCenter from "@atoms/DividerCenter";
import ConsultingCards from "@organism/ConsultingCards";
import styles from "./service-cards.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { motion } from "framer-motion";

const hide = {
  opacity: 0,
  y: 20,
};

const show = {
  opacity: 1,
  y: 0,
};

const transition = {
  type: "spring",
  stiffness: 20,
};

const ServiceCards = () => {
  const { t } = useTranslation();
  const [consultingRef, isConsultingVisible] = useOnScreen({ threshold: 0.1 });

  return (
    <section
      id='consulting'
      ref={consultingRef}
      className={`${styles.section} text-center`}
    >
      <motion.h2
        initial={hide}
        transition={transition}
        animate={isConsultingVisible ? show : hide}
      >
        {t("SERVICES.CONSULTING.TITLE")}
      </motion.h2>
      <motion.p
        initial={hide}
        transition={transition}
        animate={isConsultingVisible ? show : hide}
        className={styles.description}
      >
        {t("SERVICES.CONSULTING.SUBTITLE")}
      </motion.p>
      <ConsultingCards
        isConsultingVisible={isConsultingVisible}
        hide={hide}
        show={show}
        transition={transition}
      />
      <DividerCenter />
    </section>
  );
};

export default ServiceCards;

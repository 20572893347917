import Border from "@atoms/Border";
import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import styles from "./consulting-card.module.scss";

const ConsultingCard = ({ title, description, lottie, children }) => {
  const breakpoints = useBreakpoint();
  return (
    <div className={styles.container}>
      <Border>
        <div className={styles.content}>
          {/* TODO: LOTTIE */}
          <div
            style={{
              width: "100%",
              height: breakpoints.sm ? "auto" : 280,
              background: "rgba(255,255,255,0.1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {children || lottie}
          </div>
          {/* END LOTTIE */}

          <div className={styles.contentText}>
            <h4 className={styles.title}>{title}</h4>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
      </Border>
    </div>
  );
};

export default ConsultingCard;

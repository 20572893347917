import ConsultingCard from "@molecules/ConsultingCard";
import React from "react";
import styles from "./consulting-cards.module.scss";
import ConsultingVideoLeft from "../../../assets/video/n5consulting-left.mp4";
import ConsultingVideo from "../../../assets/video/n5consulting.mp4";
import ConsultingVideoRight from "../../../assets/video/n5consulting-right.mp4";
import { motion } from "framer-motion";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ConsultingCards = ({ isConsultingVisible, hide, show, transition }) => {
  const { t } = useTranslation();
  const videoLeftRef = React.useRef();
  const videoRightRef = React.useRef();
  React.useEffect(() => {
    if (isConsultingVisible) {
      videoLeftRef.current.play();
      videoRightRef.current.play();
    }
  }, [isConsultingVisible]);
  return (
    <motion.div
      initial={hide}
      animate={isConsultingVisible ? show : hide}
      transition={transition}
      className={styles.container}
    >
      <ConsultingCard
        title={t("SERVICES.CONSULTING.COLUMN1_TITLE")}
        description={t("SERVICES.CONSULTING.COLUMN1_SUBTITLE")}
        lottie={"Aca tiene que ir un lottie"}
      >
        <video
          ref={videoLeftRef}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          src={ConsultingVideoLeft}
          muted
          autoPlay
          playsInline
        >
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      </ConsultingCard>
      <ConsultingCard
        title={t("SERVICES.CONSULTING.COLUMN2_TITLE")}
        description={t("SERVICES.CONSULTING.COLUMN2_SUBTITLE")}
        lottie={"Aca tiene que ir un lottie"}
      >
        <video
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          src={ConsultingVideo}
          autoPlay
          playsInline
          loop
          muted
        >
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      </ConsultingCard>
      <ConsultingCard
        title={t("SERVICES.CONSULTING.COLUMN3_TITLE")}
        description={t("SERVICES.CONSULTING.COLUMN3_SUBTITLE")}
        lottie={"Aca tiene que ir un lottie"}
      >
        <video
          ref={videoRightRef}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          src={ConsultingVideoRight}
          muted
          autoPlay
          playsInline
        >
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      </ConsultingCard>
    </motion.div>
  );
};

export default ConsultingCards;
